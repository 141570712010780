import React  from "react"

// components
import Template from "../../components/Template.js"

const feeding= () => {
  return (
    <div>
      <Template index={7} ></Template>
    </div>
  )
}

export default feeding